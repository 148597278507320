<template>
  <div class="main-wrapper" data-app>
    <!-- Banner -->
    <header-two :items="banners" :key="bannerIndex" :redirect="true" />

    <div class="container">
      <v-breadcrumbs
        style="padding: 0px; color: black"
        :items="navRoutes"
        divider=">"
      ></v-breadcrumbs>
    </div>

    <!-- Content -->
    <div class="content" style="padding-top: 0px">
      <div class="container">
        <!-- Blog Posts -->
        <div class="blog-page">
          <div class="row m-0">
            <div class="col-lg-9 col-md-12 padding-right-30">
              <!-- Search -->
              <div class="col-md-12 grid-full-width page-search">
                <div class="search__container">
                  <v-row class="mx-0 my-0">
                    <v-col cols="12" sm="6" md="6" lg="5" style="padding: 6px">
                      <label for="" style="margin: auto">Palabras clave</label>
                      <v-text-field
                        solo
                        required
                        hide-details="auto"
                        label="¿Qué estás buscando?"
                        v-model="search"
                        type="text"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="4" style="padding: 6px">
                      <label for="" style="margin: auto">Categorías</label>
                      <multiselect
                        track-by="id"
                        label="name"
                        placeholder="Categorías"
                        v-model="categoryId"
                        :options="categories"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="false"
                        ><v-icon slot="append" color="red">
                          mdi-tag-multiple
                        </v-icon></multiselect
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="3"
                      lg="2"
                      style="align-self: flex-end; padding: 6px"
                    >
                      <v-row>
                        <v-col class="search__button-container">
                          <v-tooltip v-if="filterActivated" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="btn btn-primary px-2 py-1 search__button"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  ;(filterActivated = false),
                                    (search = ''),
                                    (categoryId = { id: 0, name: 'Todas' })
                                  getPublications()
                                "
                              >
                                <v-icon> mdi-backspace </v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar Filtros</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="btn btn-primary px-2 py-1 search__button"
                                @click=";(filterActivated = true), getPublications()"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon> mdi-cloud-search </v-icon>
                              </v-btn>
                            </template>
                            <span>Comenzar Busqueda</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!-- Blog Post -->
              <div class="card__container">
                <v-row
                  class="items__container card card-lift--hover border-0 mb-4"
                  v-for="post in items"
                  :key="post.id"
                >
                  <div class="card__item-img col-lg-4 col-md-5 col-sm-5 col-10">
                    <router-link
                      class="img-container"
                      :to="'/publicaciones/' + post.slug"
                    >
                      <img
                        :alt="post.name"
                        v-if="post.cover && post.cover.route"
                        class="card__img img-fluid"
                        :src="post.cover.route"
                      />
                    </router-link>
                  </div>

                  <div class="card__item-content col-lg-8 col-md-7 col-sm-7 col-12">
                    <div class="card__content-block">
                      <router-link :to="'/publicaciones/' + post.slug"
                        ><v-card-text style="position: relative">
                          <h6 class="content__title my-auto">
                            {{ post.title.slice(0, 80) }}
                          </h6>
                          <p class="text-muted" v-show="post.type !== 'events'">
                            {{
                              new Date(post.date).toLocaleDateString('es-VE', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })
                            }}
                          </p>
                          <countries-flags
                            :countries="post.countries.slice(0, 3)"
                          ></countries-flags>
                        </v-card-text>
                      </router-link>

                      <v-card-text class="content__description">
                        {{ post.shortdesc }}
                      </v-card-text>

                      <v-card-text class="events__info-container">
                        <div
                          class="col-12 col-sm-auto p-0"
                          v-show="post.type === 'events'"
                        >
                          <span v-if="post.dateRange > 0 && post.startAt"
                            ><strong>Inicia:</strong> {{ post.startAt }}</span
                          ><br v-if="post.dateRange > 0 && post.startAt" />
                          <span v-if="post.dateRange > 0 && post.expired_at"
                            ><strong>Culmina:</strong> {{ post.endAt }}</span
                          ><br v-if="post.dateRange > 0 && post.expired_at" />
                          <span v-if="post.dateRange == 0"
                            ><strong>Fecha:</strong>
                            {{ post.startAt + ' . Único día' }}</span
                          ><br v-if="post.dateRange == 0" />
                          <span v-if="post.modality"
                            ><strong>Modalidad:</strong> {{ post.modalityText }}</span
                          >
                        </div>
                        <div class="card__button-container col-12 col-sm-auto p-0">
                          <v-card-actions>
                            <v-btn
                              color="deep-purple lighten-2"
                              text
                              @click.prevent="$router.push('publicaciones/' + post.slug)"
                              class="v-card__button"
                            >
                              Ver más
                            </v-btn>
                          </v-card-actions>
                        </div>
                      </v-card-text>
                    </div>
                  </div>
                </v-row>
              </div>
              <!-- Pagination -->
              <div class="text-center pt-2" style="margin: 10px 0px">
                <v-pagination
                  @input="changePage($event)"
                  color="#5b5b5e"
                  v-model="page"
                  :length="pages"
                  :total-visible="7"
                ></v-pagination>
              </div>
            </div>

            <!-- Blog Posts / End -->
            <!-- Widgets -->
            <div class="col-lg-3 col-md-12">
              <div class="sidebar right">
                <!-- Widget -->
                <div class="widget margin-top-40">
                  <h3>Destacados</h3>
                  <ul class="widget-tabs">
                    <!-- Posts -->
                    <li v-for="post in outstandingPublications" :key="post.id">
                      <div class="widget-content">
                        <div class="widget-thumb">
                          <a :href="'/publicaciones/' + post.slug">
                            <img
                              alt=""
                              v-if="post.cover && post.cover.route"
                              class="img-fluid card__img"
                              :src="post.cover.route"
                            />
                          </a>
                        </div>
                        <div class="widget-text">
                          <a :href="'/publicaciones/' + post.slug"
                            ><h5 class="text-primary">{{ post.title }}</h5></a
                          >
                          <span>{{
                            new Date(post.date).toLocaleDateString('es-VE', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })
                          }}</span>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Widget / End-->
                <!-- Widget -->

                <!-- Widget / End-->
                <div class="clearfix"></div>
                <div class="margin-bottom-40"></div>
                <ads-section-3
                  :ads="v2Ads"
                  :key="bannerIndex"
                  style="padding: 0px"
                ></ads-section-3>
                <social></social>
              </div>
            </div>
          </div>
          <!-- Sidebar / End -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <ads-section-2 :ads="h2Ads" :key="bannerIndex"></ads-section-2>
      </div>
      <Whatsapp />
    </div>
  </div>
</template>

<script>
  import Header2 from 'Components/globalFrontendComponents/Header2'
  import AdsSection2 from 'Components/globalFrontendComponents/AdsSection2'
  import AdsSection3 from 'Components/globalFrontendComponents/AdsSection3'
  import Social from 'Components/globalFrontendComponents/Social'
  import CountriesFlags from 'Components/globalFrontendComponents/CountriesFlags'
  import Whatsapp from '@/components/globalFrontendComponents/WhatsApp'

  export default {
    name: 'PublicationType',
    props: ['publicationType', 'country'],
    data() {
      return {
        date: new Date().toISOString(),
        intervalFunction: '',
        banners: [{ route: '' }],
        location: '',
        bannerIndex: 1,
        filterActivated: false,
        search: '',
        page: 1,
        pages: 1,
        items: [],
        totalItems: 1,
        itemsPerPage: 20,
        categoryId: { id: 0, name: 'Todas' },
        categories: [{ id: 0, name: 'Todas' }],
        master: false,
        h1Ads: [],
        h2Ads: [],
        v1Ads: [],
        v2Ads: [],
        outstandingPublications: [],
        horizontal1Ads: {
          limit: 3,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        horizontal2Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        vertical1Ads: {
          limit: 0,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        vertical2Ads: {
          limit: 3,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
      }
    },
    watch: {
      country(newValue, oldValue) {
        this.horizontal2Ads.countryId = newValue.id
        this.vertical1Ads.countryId = newValue.id
        this.getAdvertisements()
      },
      publicationType(newValue, oldValue) {
        this.setBanners()
        this.bannerIndex++
        this.getPublications()
        this.getOutstandinPublications()
        this.getCategories()
      },
    },
    components: {
      headerTwo: Header2,
      AdsSection2,
      AdsSection3,
      Social,
      CountriesFlags,
      Whatsapp,
    },
    computed: {
      navRoutes() {
        if (this.typeText) {
          return [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: this.typeText,
              disabled: true,
              href: '',
            },
          ]
        } else {
          return [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: 'Publicaciones',
              disabled: true,
              href: '',
            },
          ]
        }
      },
      countryId() {
        return this.country.id
      },
      typeText() {
        switch (this.publicationType) {
          case 'blogs':
            return 'Blog'
          case 'news':
            return 'Noticias'
          case 'events':
            return 'Eventos'
          default:
            return ''
        }
      },
      categoryType() {
        switch (this.publicationType) {
          case 'news':
            return '3'
          case 'events':
            return '2'
          case 'blogs':
            return '1'
          default:
            return ''
        }
      },
    },
    created() {
      this.setNavRoutes()
      this.getCategories()
      this.setBanners()
      this.getAdvertisements()
      this.getPublications()
      this.getOutstandinPublications()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      setNavRoutes() {
        switch (this.publicationType) {
          case 'blogs':
            this.navRoutes[1].text = 'Blog'
            break
          case 'news':
            this.navRoutes[1].text = 'Noticias'
            break
          case 'events':
            this.navRoutes[1].text = 'Eventos'
            break
          default:
            return ''
        }
      },
      scrollToTop() {
        window.scrollTo(0, 0)
      },
      async getPublications() {
        this.message = 'Buscando'
        this.loading = true
        let searchStr = ''
        let orderStr = ''
        switch (this.publicationType) {
          case 'events':
            orderStr = '&order=date&by=asc'
            break
          default:
            orderStr = '&order=date&by=desc'
            break
        }
        let categoryStr = ''
        searchStr = this.search != '' ? '&search=' + this.search : ''

        categoryStr =
          this.categoryId.id != 0 && this.categoryId.id != undefined
            ? '&category_id=' + this.categoryId.id
            : ''
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?active=1&page=' +
              this.page +
              '&type=' +
              this.publicationType +
              searchStr +
              categoryStr +
              orderStr
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.message = 'No se encontraron resultados para esta búsqueda'
            }
            this.items.map((post) => {
              if (post.type === 'events') {
                post.startAt = new Date(post.date).toLocaleDateString('es-VE', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
                post.endAt = new Date(post.expired_at).toLocaleDateString('es-VE', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
                post.dateRange = Math.ceil(
                  Math.abs(new Date(post.date) - new Date(post.expired_at)) /
                    (1000 * 60 * 60 * 24)
                )
                switch (post.modality) {
                  case 'site':
                    post.modalityText = 'Presencial'
                    break
                  case 'remote':
                    post.modalityText = 'Virtual'
                    break
                  case 'remote-site':
                    post.modalityText = 'Virtual - Presencial'
                    break
                  default:
                    post.modalityText = 'N/A'
                    break
                }
              }
            })

            this.totalItems = response.data.data.slice(0, 10)
            this.itemsPerPage = response.data.per_page
            this.pages = response.data.last_page
            this.loading = false
          })
      },
      async getOutstandinPublications() {
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?active=1&order=date&by=desc&outstanding=1&limit=5&type=' +
              this.publicationType
          )
          .then((response) => {
            this.outstandingPublications = response.data
            if (this.outstandingPublications.length == 0) {
              this.message = 'No se encontraron publicaciones destacadas'
            }
          })
      },
      setBanners() {
        this.banners = [{ route: '' }]
        this.banners[0].route =
          'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/37dfb40cabdf76acb9bfd0330d271ba6.jpg'
        this.banners[0].redirect = '/franquicias'
        this.banners.push({
          route:
            'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/8533cfd5263a1c8ba66ba77a7a883670.jpg',
          redirect: '/asociaciones',
        })
        this.banners.push({
          route:
            'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/8139e9a4b40cdfa00c6f44a9c1037109.jpg',
          redirect: '/consultores',
        })
        this.banners.push({
          route:
            'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/ff4134c6277a9c79412e21940aec3951.jpg',
          redirect: '/proveedores',
        })
        this.banners.push({
          route:
            'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/09a540d527eac45a0ffd9e2f2370abd1.jpg',
          redirect: '/inmobiliaria',
        })
      },
      adsTimer() {
        this.intervalFunction = setInterval(this.getAdvertisements, 60000)
      },
      async changePage(page) {
        this.page = page
        this.items = []
        this.getPublications()
        this.scrollToTop()
      },

      async getCategories() {
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'categories?limit=100&type=' +
              this.categoryType
          )
          .then((response) => {
            this.categories = response.data
          })
      },
      getAdvertisements() {
        const h1LimitStr = this.horizontal1Ads
          ? '&horizontal1[limit]=' + this.horizontal1Ads.limit
          : '&horizontal1[limit]=0'
        const h1CtryStr = this.horizontal1Ads
          ? '&horizontal1[country_id]=' + this.horizontal1Ads.countryId
          : '&horizontal1[country_id]=235'
        const h1OutStr = this.horizontal1Ads
          ? '&horizontal1[outstanding]=' + this.horizontal1Ads.outstanding
          : '&horizontal1[outstanding]=0'
        const h2LimitStr = this.horizontal2Ads
          ? '&horizontal2[limit]=' + this.horizontal2Ads.limit
          : '&horizontal2[limit]=0'
        const h2CtryStr = this.horizontal2Ads
          ? '&horizontal2[country_id]=' + this.horizontal2Ads.countryId
          : '&horizontal2[country_id]=235'
        const h2OutStr = this.horizontal2Ads
          ? '&horizontal2[outstanding]=' + this.horizontal2Ads.outstanding
          : '&horizontal2[outstanding]=0'
        const v1LimitStr = this.vertical1Ads
          ? '&vertical1[limit]=' + this.vertical1Ads.limit
          : '&vertical1[limit]=0'
        const v1CtryStr = this.vertical1Ads
          ? '&vertical1[country_id]=' + this.vertical1Ads.countryId
          : '&vertical1[country_id]=235'
        const v1OutStr = this.vertical1Ads
          ? '&vertical1[outstanding]=' + this.vertical1Ads.outstanding
          : '&vertical1[outstanding]=0'
        const v2LimitStr = this.vertical2Ads
          ? '&vertical2[limit]=' + this.vertical2Ads.limit
          : '&vertical2[limit]=0'
        const v2CtryStr = this.vertical2Ads
          ? '&vertical2[country_id]=' + this.vertical2Ads.countryId
          : '&vertical2[country_id]=235'
        const v2OutStr = this.vertical2Ads
          ? '&vertical2[outstanding]=' + this.vertical2Ads.outstanding
          : '&vertical2[outstanding]=0'

        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisement-management/get-advertisements?' +
              h1LimitStr +
              h1CtryStr +
              h1OutStr +
              h2LimitStr +
              h2CtryStr +
              h2OutStr +
              v1LimitStr +
              v1CtryStr +
              v1OutStr +
              v2LimitStr +
              v2CtryStr +
              v2OutStr
          )
          .then((response) => {
            this.h1Ads = response.data.horizontal1
            this.h2Ads = response.data.horizontal2
            this.v1Ads = response.data.vertical1
            this.v2Ads = response.data.vertical2
            this.bannerIndex++
          })
          .catch((error) => {})
      },
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
    metaInfo() {
      return {
        title: `${this.typeText} - XPOFranchise`,
        meta: [
          {
            name: 'description',
            content:
              'Hub de Franquicias y Negocios, Marcas, Comercialización, Asesoría para Inversionistas, Software de gestión, Proveedores. Guía Internacional de Franquicias.',
          },
          {
            property: 'og:title',
            content: `${this.typeText} - XPOFranchise`,
          },
          {
            property: 'og:description',
            content:
              'Hub de Franquicias y Negocios, Marcas, Comercialización, Asesoría para Inversionistas, Software de gestión, Proveedores. Guía Internacional de Franquicias.',
          },
          {
            property: 'og:image',
            content:
              'public/static/xpo_isotipo--white.svg',
          },
          {
            property: 'og:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            property: 'og:updated_time',
            content: this.date,
          },
          {
            name: 'twitter:title',
            content: `${this.typeText} - XPOFranchise`,
          },
          {
            name: 'twitter:description',
            content:
              'Hub de Franquicias y Negocios, Marcas, Comercialización, Asesoría para Inversionistas, Software de gestión, Proveedores. Guía Internacional de Franquicias.',
          },
          {
            name: 'twitter:image',
            content:
              'public/static/xpo_isotipo--white.svg',
          },
          {
            name: 'twitter:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            name: 'og:updated_time',
            content: this.date,
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  // Responsive Variables
  $extra-large: 'only screen and (min-width: 1200px)';
  $large: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $medium: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $small: 'only screen and (min-width: 576px) and (max-width: 767px)';
  $extra-small: 'only screen and (max-width: 575px)';
  $extra-small: 'only screen and (max-width: 575px)';

  .multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 38px;
    text-align: left;
    color: #35495e;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
  }

  // Styles for Search (filter section)

  .search__container {
    margin: 0;
    padding: 9px;
    border-radius: 0px;
    width: 100%;
    max-height: inherit;
    box-shadow: 2px 2px 2px 2px rgb(51 51 51 / 15%);
    display: flex;
    align-items: flex-start;
  }
  .search__button-container {
    padding: 6px;
    margin-bottom: 6px;
    display: inline-flex;
  }
  .search__button {
    color: #fff !important;
    background-color: #5b5b5e !important;
    align-self: center;
    height: 100%;
    min-width: 30px;
    margin: 0 6px;
  }

  // Styles for Blog Post section

  // .card {
  //   display: flex !important;
  //   justify-content: center;
  //   align-items: center;
  //   // display: -webkit-inline-box;
  // }
  .v-card__subtitle,
  .v-card__text,
  .v-content__title {
    padding: 5px;
  }

  .items__container {
    margin: 20px 0;
    min-height: 9rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
  }
  .img-fluid {
    width: 100%;
    max-height: 100%;
  }
  .card__content-block {
    padding: 2%;
  }

  .content__title {
    margin-top: 10px !important;
    // font-size: 15px;
    // height: 50px;
  }
  .text-muted {
    margin-right: 10px;
    display: inline-block;
    text-transform: capitalize;
  }
  .description {
    height: 80px;
  }
  .content__country-flag {
    margin: 5px 0;
    width: 35px;
    height: 25px;
    display: table-row-group;
  }
  // .content__global-icon {
  //   font-size: xx-large;
  // }
  .events__info-container {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .card__item-content {
    padding: 0 !important;
  }
  .card__button-container {
    width: inherit;
    display: flex;
    justify-content: flex-end;
  }
  .card__button {
    justify-self: flex-end;
  }
  @media #{$small} {
    .card__container {
      margin: 0;
    }
    .card__button-container {
      padding: 0;
      width: inherit;
      display: flex;
      justify-content: flex-end;
    }
  }
  @media #{$extra-small} {
    .search__button-container {
      justify-content: flex-end;
    }
    .card__container {
      margin: 0 5%;
    }
    .card__item-img {
      min-width: -webkit-fill-available;
    }
    // .card__button-container {
    //   padding: 0;
    //   width: inherit;
    //   display: flex;
    //   justify-content: flex-end;
    // }
  }

  // @media #{$medium} {
  //   .card__image-container {
  //     justify-content: center;
  //   }
  //   .img-fluid {
  //     width: 100%;
  //     max-height: 100%;
  //   }
  // }
  // @media #{$small} {
  //   .search__button-container {
  //     margin: 5px auto;
  //     justify-content: flex-end;
  //   }
  // }
  // @media #{extra-small} {
  //   * {
  //     font-size: 12px;
  //   }
  //   .card__content-block {
  //     height: fit;
  //   }
  // }
</style>
